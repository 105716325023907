// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyBqIR-2jNdoPNNog6JUrmyqJPPmOK-loz8",
		authDomain: "eneza-dev.firebaseapp.com",
		databaseURL: "https://eneza-dev.firebaseio.com",
		projectId: "eneza-dev",
		storageBucket: "eneza-dev.appspot.com",
		messagingSenderId: "278591921298",
		appId: "1:278591921298:web:24ca9c34d512e6cd8d5a4b",
		measurementId: "G-1PV9CJ8PVH"
	},

	FB_ADMINS: "admins",
	FB_RESELLERS: "resellers",

	FB_CLIENTS: "clients",
	FB_CLIENTS_NEW: "clients_new",
	FB_CLIENTS_ADMINS: "clients_admins",
	FB_CLIENTS_ADMINS_NEW: "clients_admins_new",
	FB_LINKED_CLIENTS_ADMINS: "linked_clients_admins",

	FB_CLIENTS_EMPLOYEES: "clients_employees",
	FB_EMPLOYEES_NEW: "employees_new",
	FB_EMPLOYEES: "employees",
	FB_EMPLOYEES_REMOVED: "employees_removed",
	FB_EMPLOYEE_DEPENDANTS: "dependants",
	FB_EMPLOYEE_NEXT_OF_KIN: "next_of_kin",
	FB_DEPENDANTS_NEXT_OF_KIN: "dependants_next_of_kin",
	FB_EMPLOYEE_DOCUMENTS: "documents",
	FB_EMPLOYEE_CONTRACTS: "employee_contracts",
	FB_EMPLOYEES_IMPORTED: "employees_imported",

	FB_EMPLOYEES_LEAVES: "employee_leaves",
	FB_LEAVES: "leaves",

	FB_CLIENTS_HR_CONFIGS: "clients_hr_configs",
	FB_BRANCHES: "branches",
	FB_DEPARTMENTS: "departments",
	FB_LEAVE_TYPES: "leave_types",

	FB_CLIENTS_ASSETS: "clients_assets",
	FB_ASSETS: "assets",

	FB_CLIENTS_HR_PERFORMANCE: "clients_performance",
	FB_KPIS: "kpis",

	FB_EMPLOYEES_TASKS: "employee_tasks",
	FB_EMPLOYEES_DELETED_TASKS: "employee_deleted_tasks",
	FB_ATTACHMENT_SUB_ITEM: "attachment",

	FB_USERS: "users",
	FB_CLIENTS_USERS: "clients_users",
	FB_USERS_IMPORTS: "users_imports",
	FB_USERS_UPDATES: "users_updates",
	FB_USERS_CRITERIA_RERUN: "users_criteria_rerun",
	FB_USERS_HISTORY: "users_history",
	FB_USERS_REMOVED: "users_removed", // deleted

	FB_USERS_ACTIVATIONS: "users_activations",
	FB_USERS_ACTIVATIONS_RULES: "users_activations_rules",
	FB_USERS_VERIFY_LIST: "users_verify_list",
	FB_USERS_DISQUALIFIED_LIST: "users_disqualified_list",
	FB_USERS_CRB_DATA: "users_crb_data",
	FB_USERS_METROPOL_DATA: "users_metropol_data",
	FB_USERS_APPRAISALS: "users_appraisals",
	FB_USERS_WALLETS: "users_wallets",
	FB_USERS_NOTIFICATIONS: "users_notifications",
	FB_USERS_NOTIFICATIONS_HISTORY: "user_notifiations_history",

	FB_CLIENTS_USERS_ASSETS: "clients_users_assets",
	FB_USERS_ASSETS: "users_assets",

	FB_CLIENTS_USERS_ACTIVATIONS: "clients_users_activations",

	FB_SMES: "smes",
	FB_CLIENTS_SMES: "clients_smes",
	FB_SMES_HISTORY: "smes_history",
	FB_SMES_APPRAISALS: "smes_appraisals",
	FB_SMES_MEMBERS: "smes_members",
	FB_SMES_VERIFY_LIST: "verify_list",


	FB_MPESA_TRANSACTIONS: "mpesa_transactions",
	FB_MPESA_MANUAL_TRANSACTIONS: "mpesa_manual_transactions",

	FB_SUB_ITEMS: "items",

	FB_LOANS: "loans", // new, active, overdue, outsourced, written_off
	FB_LOANS_REPAID: "loans_repaid",
	FB_LOANS_REMOVED: "loans_removed", // cancelled, declined
	FB_LOANS_WRITTEN_OFF: "loans_written_off",
	FB_LOANS_HISTORY: "loans_history",

	FB_LOANS_INSTALMENTS: "loans_instalments",
	FB_LOANS_INSTALMENTS_REMOVED: "loans_instalments_removed",
	FB_LOANS_INSTALMENTS_REPAID: "loans_instalments_repaid",

	FB_LOANS_REPAYMENTS: "loans_repayments",
	FB_REPAYMENTS: "repayments",
	FB_LOANS_UPLOADS: "loans_uploads",
	FB_UPLOADS: "uploads",

	FB_LOANS_FOLLOW_UPS: "follow_ups",
	FB_LOANS_FOLLOW_UPS_REMOVED: "follow_ups_removed",

	FB_CLIENT_LOANS_SECURITY: "clients_loans_security",
	FB_LOANS_SECURITY: "loans_security",

	FB_LOANS_SECURED: "loans_secured",
	FB_LOANS_SECURED_REPAID: "loans_secured_repaid",
	FB_LOANS_SECURED_REMOVED: "loans_secured_removed", // cancelled, declined
	FB_LOANS_SECURED_HISTORY: "loans_secured_history",
	FB_LOANS_SECURITY_ARCHIVED: "loans_security_archived",
	FB_LOANS_SECURITY_REMOVED: "loans_security_removed",
	FB_LOANS_SECURED_IMPORTS: "loans_secured_imports",

	FB_CLIENTS_LOANS_GUARANTORS: "clients_loans_guarantors",
	FB_LOANS_GUARANTORS: "loans_guarantors",
	FB_GUARANTORS: "guarantors",
	FB_GUARANTORS_REMOVED: "guarantors_removed",

	FB_SECURED_LOANS_SETTINGS: "secured_loans_settings",
	FB_LOANS_SETTINGS: "loans_settings",
	FB_DISBURSEMENTS: "disbursements",
	FB_PAYMENTS: "payments",

	// Wallet
	FB_WALLET_TRANSACTIONS: "reseller_wallet_transactions",

	// Automatic credit topups
	FB_SMS_CREDIT_TOPUPS: "sms_credit_topups",
	FB_SMS_PAYMENT_INITIATED: "sms_payment_initiated",


	// FB_SUB_ITEMS: "items",
	FB_HISTORY_SUB_ITEMS: "history",
	FB_UPDATES_SUB_ITEMS: "updates",
	FB_SETINGS_SUB_ITEMS: "settings",
	FB_FLOAT_HISTORY: "float_history",

	FB_SMS_LOGS: "sms_logs",
	FB_BULK_SMS: "bulk_sms",

	FB_MESSAGES: "messages",
	FB_MESSAGE_TEMPLATES: "message_templates",

	FB_CONTACTS: "contacts",
	FB_CONTACTS_IMPORTS: "contacts_imports",
	FB_MPESA_CONTACTS: "mpesa_contacts",
	FB_CONTACTS_GROUPS: "contacts_groups",

	// MPESA
	FB_MPESA_BULK_TRANSACTIONS: "mpesa_bulk_transactions",
	FB_MPESA_BULK_B2B_TRANSACTIONS: "mpesa_bulk_b2b_transactions",
	FB_MPESA_PAYBILL_TRANSACTIONS: "mpesa_paybill_transactions",
	FB_MPESA_REVERSAL_TRANSACTIONS: "mpesa_reversal_transactions",
	FB_MPESA_STK_TRANSACTIONS: "mpesa_stk_transactions",
	FB_MPESA_DISBURSE_REQUESTS: "mpesa_disburse_requests",
	FB_TRANSACTIONS: "transactions",

	FB_CHARGES: "charges",
	FB_PURCHASES: "purchases",

	// API KEYS
	FB_API_KEYS: "api_keys",

	// Bursary
	FB_BURSARY_APPLICATIONS: "bursary_applications",
	FB_APPLICATIONS: "applications",
	FB_BURSARY_APPLICANTS: "bursary_applicants",
	FB_APPLICANTS: "applicants",
	FB_BURSARY_BENEFICIARIES: "bursary_beneficiaries",
	FB_BURSARY_CRITERIA: "bursary_criteria",
	FB_CRITERIA_SUBITEM: "criteria",
	FB_BURSARY_MINUTES: "bursary_minutes",
	FB_BURSARY_APPLICATIONS_UPLOAD_DATA: "bursary_application_upload_data",
	FB_BURSARY_SCHOOLS: "bursary_schools",
	FB_SCHOOLS_SUBITEM: "schools",

	// Accounting module
	FB_FINANCIAL_ACCOUNTS: "financial_accounts",
	FB_ACCOUNTS: "accounts",
	FB_ACCOUNTING_JOURNALS: "financial_journals",
	FB_JOURNALS: "journals",
	FB_ACCOUNT_ENTRIES: "journal_entries", // In the process of Deprecation
	FB_JOURNAL_ENTRIES: "financial_journals_entries",
	FB_AUTOPOSTING_ACTIONS: "autoposting_journal_actions",
	FB_INVOICES: "invoices",
	FB_CREDIT_NOTES: "credit_notes",
	FB_ACCOUNTING_AUDIT_LOGS: "accounting_audit_logs",
	FB_ONBOARDING_CHART_OF_ACCOUNTS: "onboarding_chart_of_accounts",
	FB_CUSTOMERS: "customers",
	FB_STOCK_ADJUSTMENT: "stock_adjustment",
	FB_PRODUCTS_AND_SERVICES: "products_and_services",

	// Payroll
	FB_PAYROLL: "payrolls",
	FB_PAYROLL_EMPLOYEES: "payroll_employees",

	// Account types
	FB_ASSET: "asset",
	FB_EQUITY: "equity",
	FB_LIABILITIES: "liabilities",
	FB_REVENUE: "revenue",
	FB_EXPENSES: "expenses",

	FB_RECIPIENTS: "recepients",
	FB_PRODUCTS: "products",

	// Settings
	FB_SETTINGS: "settings",
	FB_SETTINGS_SACCO: "sacco_settings",
	FB_SETTINGS_GENERAL: "general_settings",
	FB_SETTINGS_QUALIFICATION: "general_qualification",
	FB_SETTINGS_LOANS: "loans_settings",
	FB_SETTINGS_USERS: "users_settings",
	FB_SETTINGS_NORMAL_LOANS: "normal",
	FB_SETTINGS_SECURED_LOANS: "secured",
	FB_SETTINGS_SCHOOL_LOANS: "school",
	FB_SETTINGS_MEMBERS: "members_settings",
	FB_SETTINGS_HR: "hr_settings",
	FB_SETTINGS_MESSAGES: "messages_settings",
	FB_SETTINGS_ACCOUNTING: "accounting_settings",
	FB_SETTINGS_ACCOUNTING_SALES: "sales_settings",
	FB_SETTINGS_ACCOUNTING_TAXES: "taxes",
	FB_SETTINGS_ACCOUNTING_EXPENSES_POSTING_ACCS: "expenses_posting_accounts",
	FB_SETTINGS_PAYROLL: "payroll_settings",
	FB_SETTINGS_PAYROLL_ENTITIES: "payroll_entities",
	FB_SETTINGS_PAYROLL_RECURRING_INCOMES: "payroll_recurring_incomes",
	FB_SETTINGS_PAYROLL_RECURRING_DEDUCTIONS: "payroll_recurring_deductions",
	FB_SETTINGS_PAYROLL_QB_POSTING_ACCOUNTS: "quickbooks_posting_accounts",
	FB_SETTINGS_PAYROLL_ACC_POSTING_ACCOUNTS: "accounting_posting_accounts",
	FB_SETTINGS_DISBURSEMENTS: "disbursement_settings",
	FB_DISPLAY: "display",

	// Counters
	FB_COUNTERS: "counters",
	FB_COUNTERS_SACCO: "sacco_counters",

	// Imports
	FB_IMPORTS: "imports",
	FB_IMPORTS_SACCO: "sacco_imports",

	FB_EXCESS_PAID_AMOUNTS: "excess_paid_amounts",

	FB_OTP_SESSIONS: "otp_sessions",

	FB_CLIENTS_FLOAT_REQUESTS: "clients_float_requests",
	FB_FLOAT_REQUESTS: "float_requests",
	FB_FLOAT_REQUESTS_HISTORY: "float_requests_history",
	FB_CLIENTS_FLOAT_HISTORY: "clients_float_history",
	HISTORY_MANUAL_FLOAT_EDITS: "manual_float_edits",
	HISTORY_MANUAL_SMS_CREDITS_EDITS: "manual_sms_credits_edits",

	FB_CLIENTS_EXPENSES: "clients_expenses",

	METROPOL_CRB_DATA: "metropol",
	CREDIT_INFO_CRB_DATA: "credit_info",

	// Loan status
	LOAN_STATUS_PENDING: "pending",
	LOAN_STATUS_DECLINED: "declined",
	LOAN_STATUS_APPROVED: "approved",
	LOAN_STATUS_BATCHED: "batched",
	LOAN_STATUS_DISBURSED: "disbursed",
	LOAN_STATUS_OVERDUE: "overdue",
	LOAN_STATUS_OUTSOURCED: "outsourced",
	LOAN_STATUS_UNSERVICED: "unserviced",
	LOAN_STATUS_REPAID: "repaid",
	LOAN_STATUS_REPAID_PARTIAL: "repaid_partial",
	LOAN_STATUS_CANCELLED: "cancelled",
	LOAN_STATUS_WRITTEN_OFF: "writtenOff",
	LOAN_STATUS_OVERDUE_ROLLOVER: "overdue_rollover",
	LOAN_STATUS_OVERDUE_ROLLOVER_STOP: "overdue_rollover_stop",
	LOAN_STATUS_CANCELLED_MANUALLY: "cancelled_manually",
	LOAN_STATUS_WRITTEN_OFF_MANUALLY: "written_off_manually",
	LOAN_STATUS_CANCELLED_CONFIRMED: "cancelled_confirmed",
	LOAN_STATUS_INITIATE_DISBURSE_MANUALLY: "initiate_disburse_manually",
	LOAN_STATUS_REASSESSED_MANUALLY: "reassessed_manually",
	LOAN_STATUS_DISBURSED_MANUALLY: "disbursed_manually",
	LOAN_STATUS_RENEWED_MANUALLY: "renewed_manually",
	LOAN_STATUS_REPAID_MANUALLY: "repaid_manually",
	LOAN_STATUS_REPAID_CONFIRMED: "repaid_confirmed",
	LOAN_STATUS_REPAID_PARTIAL_MANUALLY: "repaid_partial_manually",
	LOAN_STATUS_REPAID_PARTIAL_CONFIRMED: "repaid_partial_confirmed",
	LOAN_STATUS_PAID_IN_TIME_MANUALLY: "paid_in_time_manually",
	LOAN_STATUS_RATE_ADJUSTED_MANUALLY: "rate_adjusted_manually",
	LOAN_STATUS_MOVED_MANUALLY: "moved_manually",
	LOAN_STATUS_LOW_LIMIT: "low_user_loan_limit",
	LOAN_STATUS_MARKED_AS_FRAUD: "marked_as_fraud",
	LOAN_STATUS_ABORT_CONFIRM_AS_FRAUD: "abort_confirm_action",
	LOAN_STATUS_RESTORED_MANUALLY: "restored_manually",
	LOAN_DURATION_ADJUSTED_MANUALLY: "duration_adjusted_manually",

	STATUS_DECLINED: "declined",
	STATUS_PENDING: "pending",
	STATUS_REQUESTED: "requested",
	STATUS_ACTIVE: "active",
	STATUS_SCHEDULED: "scheduled",
	STATUS_PENDING_APPROVAL: "pending_approval",
	STATUS_TAKEN: "taken",
	STATUS_CANCELLED: "cancelled",
	STATUS_UN_ASSIGNED: "un-assigned",
	STATUS_ASSIGNED: "assigned",
	STATUS_NEW: "new",
	STATUS_APPRAISED: "appraised", // TO DO ACTION ON FUNCTIONS
	STATUS_APPROVED: "approved",
	STATUS_DONE: "done",
	STATUS_INITIATED: "initiated",
	STATUS_OVERDUE: "overdue",
	STATUS_CLOSED: "closed",
	STATUS_COMPLETED: "completed",
	STATUS_REJECTED: "rejected",
	STATUS_RE_OPENED: "re-opened",
	STATUS_MANUAL_EDITS: "manual_edits",
	STATUS_TIME_EXTENSION_EDITS: "time_extension_edits",
	STATUS_DISBURSED: "disbursed",
	STATUS_PROCESSING: "processing",
	STATUS_FAILED: "failed",

	DEFAULT_LOAN_RATE: 15,
	LOAN_PROGRESSION_CAP: 30000,
	LOAN_PROGRESSION_AMOUNT: 500,

	// loan status codes
	LOAN_CODE_PENDING: 0,
	LOAN_CODE_DECLINED: 1,
	LOAN_CODE_APPROVED: 2,
	LOAN_CODE_DISBURSED: 3,
	LOAN_CODE_OVERDUE: 4,
	LOAN_CODE_REPAID: 5,
	LOAN_CODE_CANCELLED: 6,
	LOAN_CODE_RENEWED: 7,
	LOAN_CODE_REPAID_PARTIAL: 8,
	LOAN_CODE_OVERDUE_ROLLOVER: 9,
	LOAN_CODE_OUTSOURCED: 10,
	LOAN_CODE_RATE_CHANGED: 11,
	LOAN_CODE_RATE_ADJUSTED: 12,
	LOAN_CODE_START_ROLLOVER: 13,
	LOAN_CODE_END_ROLLOVER: 14,
	LOAN_CODE_ROLLOVER_WAIVER: 15,
	LOAN_CODE_ROLLOVER_UNDO_WAIVER: 16,
	LOAN_CODE_LOW_LOAN_LIMIT: 17,
	LOAN_CODE_UNDO_PARTIAL_PAYMENT: 18,
	LOAN_CODE_WRITTEN_OFF: 999,
	DEFAULT_HAKIKISHA_AMOUNT: 10,

	// Message status
	MESSAGE_STATUS_PENDING: "pending",
	MESSAGE_STATUS_QUEUED: "queued",
	MESSAGE_STATUS_SENT: "sent",
	MESSAGE_STATUS_FAILED: "failed",
	MESSAGE_STATUS_PROCESSING: "processing",
	MESSAGE_STATUS_COMPLETED: "completed",
	MESSAGE_STATUS_CANCELLED: "cancelled",

	ENEZA_CLIENT_CODE: "client_code",
	ALLOWED_CLIENT_CODES: "allowed_client_codes",

	TRANSACTION_STATUS_INITIATED: "initiated",
	TRANSACTION_STATUS_CONFIRMED: "confirmed",
	TRANSACTION_STATUS_PROCESSING: "processing",
	TRANSACTION_STATUS_DISBURSED: "disbursed",
	TRANSACTION_STATUS_CANCELLED: "cancelled",
	TRANSACTION_STATUS_SUCCESSFUL: "successful", // bulk & paybill
	TRANSACTION_STATUS_FAILED: "failed", // bulk & paybill

	TRANSACTION_TYPE_TILL: "till",
	TRANSACTION_TYPE_PAYBILL: "paybill",

	APPLICATION_STATUS_PENDING: "pending",
	APPLICATION_STATUS_VERIFIED: "verified",
	APPLICATION_STATUS_QUALIFIED: "qualified",
	APPLICATION_STATUS_AWARDED: "awarded",
	APPLICATION_STATUS_NOT_QUALIFIED: "not_qualified",
	APPLICATION_STATUS_PENDING_DISBURSED: "pending_disbursed",
	APPLICATION_STATUS_DISBURSED: "disbursed",

	COMPANY_DEPARTMENTS: [
		"Accounting", "Administration", "Revenue Collection", "Customer Service", "Dispatch", "Finance", "Human Resource", "Information Technology (IT)",
		"Maintenance", "Marketing & Advertising", "Production", "Sales"
	],

	DEFAULT_CURRENCY: "KES",
	DEFAULT_LOAN_DURATION: 1, // 1 month
	DEFAULT_LOAN_INTEREST_RATE: 15,
	DEFAULT_LOAN_PROCESSING_FEE: 300,

	FUNCTIONS_URL: "https://us-central1-eneza-dev.cloudfunctions.net"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
