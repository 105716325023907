import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFirePerformanceModule } from "@angular/fire/performance";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatetimepickerModule, MatNativeDatetimeModule } from "@mat-datetimepicker/core";
import { SimpleNotificationsModule } from "angular2-notifications";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { AgmCoreModule } from "@agm/core";

// Add the firebase libraries
import "firebase/analytics";
import "firebase/performance";
import "firebase/functions";

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireStorageModule,
		AngularFirePerformanceModule,
		AngularFireModule.initializeApp(environment.firebase),

		MatDatetimepickerModule,
		MatNativeDatetimeModule,
		MatDatepickerModule,
		MatNativeDateModule,

		SimpleNotificationsModule.forRoot(),

		AgmCoreModule.forRoot({ apiKey: "AIzaSyCZRhqUNoBX1IkIoTurdZgUljisLZL9-oU" }),
		AppRoutingModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
