import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
	{ path: "", redirectTo: "auth/login", pathMatch: "full" },
	{ path: "auth", loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule) },
	{ path: "home", loadChildren: () => import("./home/home.module").then((m) => m.HomeModule) },
	{ path: "reseller", loadChildren: () => import("./reseller/reseller.module").then((m) => m.ResellerModule) },
	{ path: "setup", loadChildren: () => import("./onboarding/onboarding.module").then((m) => m.ClientOnboardingModule) },
	{ path: "**", redirectTo: "auth/notfound", pathMatch: "full" }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
