import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import firebase from "firebase/app";
import "firebase/auth";

declare function require(moduleName: string): any;
const { version: appVersion } = require("../../package.json");

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {

	public date: Date;
	public appVersion: any;

	public options = {
		timeOut: 5000,
		preventDuplicates: true,
		position: ["right", "top"]
	};

	constructor (private title: Title) {
		this.title.setTitle("Client Web");
	}

	ngOnInit() {
		this.date = new Date();
		this.appVersion = appVersion;

		firebase.analytics();
		firebase.auth().onAuthStateChanged(async user => {
			if (user) {
				firebase.analytics().setUserId(user.uid);
				// const token = await firebase.auth().currentUser.getIdTokenResult();
				// firebase.analytics().setUserProperties({ roles: (token.claims.roles || []) });
				// console.log("Auth > token.claims:", token.claims);
			}
		});
	}

}
